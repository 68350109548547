import React from 'react';
import { graphql, Link } from 'gatsby';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../components/layout';
import LastPosts from '../components/lastPosts';
import SEO from '../components/seo';

const BlogIndexList = ({ data, pageContext, location }) => {
  const parent = data.markdownRemark;
  const posts = data.allMarkdownRemark.edges;
  const siteTitle = data.site.siteMetadata.title;
  const { numPages, currentPage } = pageContext;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={parent.frontmatter.title} description={parent.frontmatter.description || parent.excerpt} />
      <LastPosts posts={posts} title={parent.frontmatter.title} description={parent.frontmatter.description} />
      <div className="mx-auto max-w-6xl">
        <hr />
        <div
          className={`flex px-3 py-4 ${
            currentPage < numPages && 1 < currentPage
              ? 'justify-between'
              : 1 < currentPage
              ? 'justify-end'
              : 'justify-start'
          }`}
        >
          {currentPage < numPages && (
            <Link className="no-underline flex items-center" to={`/blog/pag-${currentPage + 1}`}>
              <FontAwesomeIcon className="text-xs mr-2" icon={faArrowLeft} /> Anteriores
            </Link>
          )}
          {1 < currentPage && (
            <Link className="no-underline" to={`/blog/${currentPage - 1 > 1 ? `pag-${currentPage - 1}` : ''}`}>
              Nuevos <FontAwesomeIcon className="text-xs ml-2" icon={faArrowRight} />
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default BlogIndexList;

export const pageQuery = graphql`
  query BlogIndexListQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        description
        author
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { parent: { regex: $slug }, index: { eq: null } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD/MM/YYYY")
            title
            description
            tags
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
